import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import des icônes pour le menu hamburger

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="p-8 bg-gray-800 text-white sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center relative">
        {/* Logo centré */}
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <Link to="/">
            <img
              src="Logo_TheBeat_colo.png"
              alt="N'JOY THE BEAT"
              className="h-24 w-auto" // Taille personnalisée du logo
            />
          </Link>
        </div>

        {/* Menu Hamburger - à droite */}
        <div className="lg:hidden flex items-center absolute right-4 top-1/2 transform -translate-y-1/2">
          <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        {/* Menu déroulant pour mobile */}
        {isMobileMenuOpen && (
          <div className="absolute top-full right-0 bg-gray-700 rounded-lg shadow-lg w-40 mt-2">
            <ul className="flex flex-col space-y-2 p-4">
              <li>
                <Link
                  to="/live-sessions"
                  className="block text-orange-500 hover:text-orange-400"
                  onClick={toggleMobileMenu}
                >
                  Live Sessions
                </Link>
              </li>
              <li>
                <Link
                  to="/inside-the-beat"
                  className="block text-orange-500 hover:text-orange-400"
                  onClick={toggleMobileMenu}
                >
                  Inside the Beat
                </Link>
              </li>
              <li>
                <Link
                  to="/beat-stories"
                  className="block text-orange-500 hover:text-orange-400"
                  onClick={toggleMobileMenu}
                >
                  Beat Stories
                </Link>
              </li>
              <li>
                <Link
                  to="/give-me-5"
                  className="block text-orange-500 hover:text-orange-400"
                  onClick={toggleMobileMenu}
                >
                  Give Me 5
                </Link>
              </li>
              <li>
                <Link
                  to="/pov-tlmlv"
                  className="block text-orange-500 hover:text-orange-400"
                  onClick={toggleMobileMenu}
                >
                  POV/TLMLV
                </Link>
              </li>
            </ul>
          </div>
        )}

        {/* Menu desktop */}
        <ul className="hidden lg:flex items-center space-x-4 absolute right-4 top-1/2 transform -translate-y-1/2">
          <li>
            <Link to="/live-sessions" className="hover:text-orange-500">
              Live Sessions
            </Link>
          </li>
          <li>
            <Link to="/inside-the-beat" className="hover:text-orange-500">
              Inside the Beat
            </Link>
          </li>
          <li>
            <Link to="/beat-stories" className="hover:text-orange-500">
              Beat Stories
            </Link>
          </li>
          <li>
            <Link to="/give-me-5" className="hover:text-orange-500">
              Give Me 5
            </Link>
          </li>
          <li>
            <Link to="/pov-tlmlv" className="hover:text-orange-500">
            POV/TLMLV
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
