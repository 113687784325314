import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className="flex justify-center space-x-6 bg-gray-800 text-white py-4">
      <a href="https://www.youtube.com/@njoy.belgium" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube text-2xl"></i>
      </a>
      <a href="https://www.instagram.com/njoy.be?igsh=MTVzMGYzamZ5ZGwwag==" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram text-2xl"></i>
      </a>
      <a href="https://www.tiktok.com/@njoythebeat?_t=ZG-8sp0DbfMnir&_r=1" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-tiktok text-2xl"></i>
      </a>
      <a href="https://x.com/njoythebeat?s=21" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter text-2xl"></i>
      </a>
      <a href="https://www.facebook.com/share/oD8hhEzmDkWVjx2r/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook text-2xl"></i>
      </a>
      <a href="https://open.spotify.com/playlist/1AWyiZOkYyKlS9RrWjSpoD?si=BZjzip_gR7GdlfIr7uL_bQ" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-spotify text-2xl"></i>
      </a>
    </footer>
  );
};

export default Footer;
