import React from 'react';

const PointOfView = () => {
  return (
    <div id="point-of-view" className="p-6 bg-gray-900 text-gray-800">
      {/* Conteneur principal avec disposition flex */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-6 lg:space-y-0 lg:space-x-6">
        {/* Section texte */}
        <div className="flex-1">
          {/* Titre principal */}
          <h1 className="text-3xl font-bold text-left my-6 text-white">
          MY POV/TLMLV : Vivez les événements comme si vous y étiez
          </h1>

          {/* Introduction */}
          <p className="text-lg text-justify leading-relaxed text-orange-500">
            <strong className='text-white'>MY POV/TLMLV</strong> sont des immersions unique au cœur des événements musicaux et culturels. Ces concepts
            vous font vivre concerts, festivals, tournages de clips et bien plus encore, à travers les yeux de nos hôtes. 
            En capturant leurs expériences comme de simples spectateurs, <strong className='text-white'>POV/TLMLV</strong> crée une connexion 
            authentique avec son audience.
          </p>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Une immersion à 360° :</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Des expériences uniques</strong> : Nos hôtes vous emmènent au plus près des moments forts, qu’il 
              s’agisse de l’effervescence d’un concert, de l’énergie d’un festival ou des secrets des coulisses.
            </li>
            <li>
              <strong className='text-white'>Un regard authentique</strong> : Plongez dans des récits vécus et ressentis, où chaque émotion est partagée 
              comme si vous y étiez.
            </li>
            <li>
              <strong className='text-white'>Une approche humaine</strong> : Ce format met en avant une perspective accessible et identifiable, 
              reflétant la réalité d’un fan ou d’un consommateur de musique.
            </li>
          </ul>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Pourquoi suivre ces deux concepts ?</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Un lien renforcé avec la communauté</strong> : En partageant les ressentis et l’enthousiasme de nos hôtes, 
              POV rapproche les fans et crée une véritable complicité. TLMLV permet de montrer l'envers du décor que le fan ne perçoit pas systématiquement
            </li>
            <li>
              <strong className='text-white'>Une diversité de lieux et d’événements</strong> : Des grandes scènes aux instants plus intimes en coulisses, 
              chaque épisode propose une expérience nouvelle et captivante.
            </li>
            <li>
              <strong className='text-white'>Une approche immersive</strong> : Vivez chaque instant comme si vous y étiez, grâce à des récits sincères 
              et des images vibrantes.
            </li>
          </ul>
        </div>

        {/* Image */}
        <div className="flex-shrink-0">
          <img
            src="POV.png"
            alt="Point of View"
            className="w-full max-w-xs lg:max-w-md object-cover rounded-lg"
          />
        </div>
      </div>

      {/* Conclusion */}
      <p className="mt-8 text-lg leading-relaxed text-justify text-orange-500">
        Rejoignez <strong className='text-white'>POV/TLMLV</strong> pour explorer des événements musicaux et culturels d’une manière nouvelle, 
        authentique et proche de votre réalité. Un format pensé pour vous faire vibrer et partager la passion de la musique !
      </p>
    </div>
  );
};

export default PointOfView;
